
import { defineComponent, onMounted } from "vue";
import { useScript } from "@/helper";

import Layout from "@/templates/layout/Layout.vue";

export default defineComponent({
  name: "Home",
  components: { Layout },
  setup() {
    onMounted(() => useScript("home"));
  },
});
