import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Layout = _resolveComponent("Layout")

  return (_openBlock(), _createBlock(_component_Layout, {
    bodyClass: "p-home is-keyword-hidden is-menu-open",
    splash: true,
    homeMenuLogo: true,
    noHeader: true,
    asHome: true
  }))
}